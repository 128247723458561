import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
  useTheme,
} from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { featureAPI } from '../services/api';

const colorConfig = {
  light: {
    header: {
      background: 'rgba(230, 230, 250, 0.5)',
      hover: 'rgba(230, 230, 250, 0.7)'
    },
    button: {
      primary: '#5C6BC0',
      success: '#2E7D32',
    }
  },
  dark: {
    header: {
      background: 'rgba(50, 40, 80, 0.3)',
      hover: 'rgba(50, 40, 80, 0.4)'
    },
    button: {
      primary: '#7986CB',
      success: '#81C784',
    }
  }
};

const FeatureRequest: React.FC = () => {
  const [feature, setFeature] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [requestSent, setRequestSent] = useState(false);
  const theme = useTheme();
  const colors = colorConfig[theme.palette.mode as 'light' | 'dark'];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!feature.trim()) {
      setError('Please describe your feature request');
      return;
    }

    try {
      setLoading(true);
      const response = await featureAPI.submitRequest(feature.trim());

      if (response.data.success) {
        setSuccess('Thank you for your feature request! We will review it and consider it for future updates.');
        setRequestSent(true);
        setFeature('');
      } else {
        setError(response.data.message || 'Failed to submit feature request');
      }
    } catch (error) {
      setError('Error submitting feature request. Please try again later.');
      console.error('Error submitting feature request:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={1} sx={{ overflow: 'hidden' }}>
        <Box sx={{ 
          p: 2, 
          bgcolor: colors.header.background,
          transition: 'background-color 0.2s ease',
          borderBottom: 1,
          borderColor: theme.palette.mode === 'light' 
            ? 'rgba(0, 0, 0, 0.05)' 
            : 'rgba(255, 255, 255, 0.05)',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <LightbulbIcon sx={{ color: colors.button.primary }} />
          <Typography variant="h5" component="h2">
            Feature Request
          </Typography>
        </Box>
        
        <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
          <Typography paragraph>
            Have an idea for a new feature? We'd love to hear it! Please describe your suggestion below, and we'll carefully consider it for future updates.
          </Typography>

          {!requestSent ? (
            <>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                placeholder="Describe your feature request here..."
                value={feature}
                onChange={(e) => setFeature(e.target.value)}
                disabled={loading}
                sx={{ mb: 3 }}
              />
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                sx={{
                  bgcolor: colors.button.primary,
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'light' 
                      ? 'primary.dark'
                      : 'primary.light',
                  }
                }}
              >
                Submit Request
              </Button>
            </>
          ) : (
            <Box sx={{ 
              textAlign: 'center', 
              py: 3,
              px: 2,
              bgcolor: theme.palette.mode === 'light' 
                ? 'rgba(46, 125, 50, 0.1)'  // Light green background
                : 'rgba(129, 199, 132, 0.1)', // Dark green background
              borderRadius: 1
            }}>
              <CheckCircleOutlineIcon 
                sx={{ 
                  fontSize: 48, 
                  color: colors.button.success,
                  mb: 2
                }} 
              />
              <Typography variant="h6" sx={{ color: colors.button.success }} gutterBottom>
                Feature Request Submitted Successfully!
              </Typography>
              <Typography color="textSecondary" paragraph>
                Thank you for helping us improve our product.
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  setRequestSent(false);
                  setFeature('');
                }}
                sx={{
                  mt: 2,
                  color: colors.button.success,
                  borderColor: colors.button.success,
                  '&:hover': {
                    borderColor: colors.button.success,
                    backgroundColor: `${colors.button.success}10`
                  }
                }}
                startIcon={<LightbulbIcon />}
              >
                Submit Another Feature Request
              </Button>
            </Box>
          )}
        </Box>
      </Paper>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!success} 
        autoHideDuration={6000} 
        onClose={() => setSuccess(null)}
      >
        <Alert severity="success" onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FeatureRequest;
