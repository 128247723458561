import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Alert,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { User } from '../types/user';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonIcon from '@mui/icons-material/Person';
import { authAPI } from '../services/api';

// Sophisticated color configuration
const colorConfig = {
  light: {
    header: {
      background: 'rgba(230, 245, 255, 0.5)',  // Soft blue
      hover: 'rgba(230, 245, 255, 0.7)'
    },
    button: {
      primary: '#5C6BC0',    // Indigo
      success: '#2E7D32',    // Dark Green
    }
  },
  dark: {
    header: {
      background: 'rgba(40, 50, 90, 0.3)',     // Deep blue
      hover: 'rgba(40, 50, 90, 0.4)'
    },
    button: {
      primary: '#7986CB',    // Lighter Indigo
      success: '#81C784',    // Lighter Green
    }
  }
};

interface ProfileFormData {
  name: string;
  phone: string;
  gender: string;
  birthday: string;
}

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const theme = useTheme();
  const colors = colorConfig[theme.palette.mode as 'light' | 'dark'];
  
  const [formData, setFormData] = useState<ProfileFormData>({
    name: '',
    phone: '',
    gender: '',
    birthday: '',
  });

  const initializeFormData = () => {
    if (user) {
      setFormData({
        name: user.name || '',
        phone: user.phone || '',
        gender: user.gender || '',
        birthday: user.birthday ? user.birthday.split('T')[0] : '',
      });
    }
  };

  useEffect(() => {
    initializeFormData();
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (!user) return;
    
    setSaving(true);
    setError(null);
    setSuccess(null);

    try {
      const updatedUser: User = {
        ...user,
        ...formData
      };
      
      const response = await authAPI.updateProfile(updatedUser);
      const data = response.data;

      if (data.success) {
        updateUser(updatedUser);
        setSuccess('Profile updated successfully!');
        setIsEditing(false);
      } else {
        setError(data.message || 'Failed to update profile');
      }
    } catch (error) {
      setError('An error occurred while updating the profile');
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    initializeFormData();
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={1} sx={{ overflow: 'hidden' }}>
        <Box sx={{ 
          p: 2, 
          bgcolor: colors.header.background,
          transition: 'background-color 0.2s ease',
          borderBottom: 1,
          borderColor: theme.palette.mode === 'light' 
            ? 'rgba(0, 0, 0, 0.05)' 
            : 'rgba(255, 255, 255, 0.05)',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <PersonIcon sx={{ color: colors.button.primary }} />
          <Typography variant="h5" component="h2">
            Profile
          </Typography>
        </Box>

        <Box sx={{ p: 3 }}>
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {success}
            </Alert>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
            {!isEditing ? (
              <Button
                startIcon={<EditIcon />}
                onClick={() => setIsEditing(true)}
                variant="outlined"
                sx={{
                  color: colors.button.primary,
                  borderColor: colors.button.primary,
                  '&:hover': {
                    borderColor: colors.button.primary,
                    backgroundColor: `${colors.button.primary}10`
                  }
                }}
              >
                Edit Profile
              </Button>
            ) : (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  variant="outlined"
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
                  onClick={handleSave}
                  variant="contained"
                  sx={{
                    bgcolor: colors.button.primary,
                    '&:hover': {
                      bgcolor: colors.button.primary,
                    }
                  }}
                  disabled={saving}
                >
                  Save Changes
                </Button>
              </Box>
            )}
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                value={user?.email || ''}
                disabled
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                disabled={!isEditing}
                placeholder="Enter your name"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                disabled={!isEditing}
                placeholder="Enter your phone number"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth disabled={!isEditing}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  label="Gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="">
                    <em>Prefer not to say</em>
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Birthday"
                name="birthday"
                type="date"
                value={formData.birthday}
                onChange={handleInputChange}
                disabled={!isEditing}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default Profile;
