import React from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useTheme } from './contexts/ThemeContext';
import Login from './components/auth/Login';
import Recipients from './components/recipients/Recipients';
import Contact from './components/Contact';
import FeatureRequest from './components/FeatureRequest';
import ChangePassword from './components/auth/ChangePassword';
import Profile from './components/Profile';
import { useAuth } from './contexts/AuthContext';
import Navigation from './components/layout/Navigation';
import Footer from './components/layout/Footer';
import About from './pages/About';
import SupportPage from './pages/SupportPage';
import { Box } from '@mui/material';

function App() {
  const { isAuthenticated } = useAuth();
  const { isDarkMode } = useTheme();

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
    },
  });

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ 
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.default',
          color: 'text.primary',
        }}>
          {isAuthenticated && <Navigation />}
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Routes>
              <Route 
                path="/login" 
                element={!isAuthenticated ? <Login /> : <Navigate to="/" />} 
              />
              <Route 
                path="/profile"
                element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
              />
              <Route 
                path="/" 
                element={isAuthenticated ? <Recipients /> : <Navigate to="/login" />} 
              />
              <Route 
                path="/contact" 
                element={isAuthenticated ? <Contact /> : <Navigate to="/login" />} 
              />
              <Route 
                path="/feature-request" 
                element={isAuthenticated ? <FeatureRequest /> : <Navigate to="/login" />} 
              />
              <Route 
                path="/change-password" 
                element={isAuthenticated ? <ChangePassword /> : <Navigate to="/login" />} 
              />
              {/* About route - commented out for initial testing
              <Route 
                path="/about" 
                element={isAuthenticated ? <About /> : <Navigate to="/login" />} 
              />
              */}
              <Route 
                path="/support" 
                element={isAuthenticated ? <SupportPage /> : <Navigate to="/login" />} 
              />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </Router>
    </MUIThemeProvider>
  );
}

export default App;
