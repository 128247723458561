import React, { useState, useEffect, useCallback, memo, useRef, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Alert,
  Snackbar,
  CircularProgress,
  TextField,
  Link,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { giftsAPI } from '../../services/api';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTheme } from '@mui/material/styles';
import { AxiosResponse } from 'axios';

interface GiftListProps {
  recipientId: number;
  isEditing: boolean;
  onSuggestionChange?: (suggestions: GiftSuggestion[]) => void;
  onEmptySuggestions?: () => void;
  generatingSuggestions?: boolean;
}

interface GiftSuggestion {
  id: number;
  description: string;
  likes: number;
  url?: string;
  ai_created: boolean;
  user_input: boolean;
  order_index: number;
  is_active: boolean;
}

interface FormData {
  description: string;
  url: string;
}

interface SortableItemProps {
  suggestion: GiftSuggestion;
  onLike: (id: number) => void;
  onDislike: (id: number) => void;
  onEdit: (suggestion: GiftSuggestion) => void;
  onDelete: (id: number) => void;
  index: number;
  total: number;
}

interface ApiResponse {
  success: boolean;
  data: GiftSuggestion[];
  message?: string;
}

const colorConfig = {
  light: {
    start: 'rgba(220, 242, 232, 0.5)', // Sophisticated light green
    end: 'rgba(253, 226, 226, 0.5)'    // Sophisticated light red
  },
  dark: {
    start: 'rgba(24, 70, 50, 0.3)',    // Sophisticated dark green
    end: 'rgba(80, 24, 24, 0.3)'       // Sophisticated dark red
  }
};

const getBackgroundColor = (index: number, total: number, mode: 'light' | 'dark' = 'light') => {
  const colors = colorConfig[mode];
  if (total === 1) return colors.start;
  
  const progress = index / (total - 1);
  const startRGB = colors.start.match(/\d+/g)!.map(Number);
  const endRGB = colors.end.match(/\d+/g)!.map(Number);
  
  const r = Math.round(startRGB[0] + (endRGB[0] - startRGB[0]) * progress);
  const g = Math.round(startRGB[1] + (endRGB[1] - startRGB[1]) * progress);
  const b = Math.round(startRGB[2] + (endRGB[2] - startRGB[2]) * progress);
  const a = 0.5; // Keeping alpha consistent

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const SortableItem = ({ suggestion, onLike, onDislike, onEdit, onDelete, index, total }: SortableItemProps) => {
  const theme = useTheme();
  const backgroundColor = getBackgroundColor(index, total, theme.palette.mode as 'light' | 'dark');
  
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: suggestion.id.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    width: '100%'
  };

  // Helper function to determine button color based on state
  const getButtonColor = (isActive: boolean, activeColor: string) => {
    return isActive ? activeColor : 'action.active'; // Use color feedback when active, subtle gray when inactive
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Card sx={{
        width: '100%',
        transition: 'all 0.2s ease',
        boxShadow: isDragging ? '0 8px 16px rgba(0,0,0,0.1)' : undefined,
        backgroundColor,
        transform: isDragging ? 'scale(1.02)' : 'scale(1)',
        '&:hover': {
          backgroundColor: theme.palette.mode === 'light' 
            ? `rgba(${backgroundColor.match(/\d+/g)!.slice(0, 3).join(', ')}, 0.7)`
            : `rgba(${backgroundColor.match(/\d+/g)!.slice(0, 3).join(', ')}, 0.4)`,
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        }
      }}>
        <CardContent 
          sx={{ 
            py: 1, 
            px: 2, 
            '&:last-child': { pb: 1 },
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: '100%'
          }}
        >
          {/* Draggable area */}
          <Box 
            {...attributes} 
            {...listeners}
            sx={{ 
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              cursor: 'grab',
              '&:active': { cursor: 'grabbing' },
            }}
          >
            <Typography variant="body1" sx={{ flex: 1, minWidth: 0 }}>
              {suggestion.description}
              {suggestion.ai_created && !suggestion.user_input && (
                <SmartToyIcon color="primary" sx={{ fontSize: 16, ml: 1, verticalAlign: 'text-bottom' }} />
              )}
              {suggestion.url && (
                <Link href={suggestion.url} target="_blank" rel="noopener noreferrer" sx={{ ml: 1 }}>
                  (link)
                </Link>
              )}
            </Typography>
          </Box>
          
          {/* Button section - explicitly excluded from drag area */}
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            flexShrink: 0
          }}>
            {/* Like/Dislike row */}
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <IconButton
                size="small"
                onClick={() => onLike(suggestion.id)}
                sx={{ 
                  p: 0.5,
                  color: getButtonColor(suggestion.likes > 0, 'primary.main')
                }}
              >
                <ThumbUpIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => onDislike(suggestion.id)}
                sx={{ 
                  p: 0.5,
                  color: getButtonColor(suggestion.likes < 0, 'error.main')
                }}
              >
                <ThumbDownIcon fontSize="small" />
              </IconButton>
            </Box>
            
            {/* Edit/Delete row */}
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <IconButton
                size="small"
                onClick={() => onEdit(suggestion)}
                sx={{ p: 0.5 }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => onDelete(suggestion.id)}
                sx={{ p: 0.5 }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

const GiftList = memo(({ recipientId, isEditing, onEmptySuggestions, generatingSuggestions }: GiftListProps) => {
  const [suggestions, setSuggestions] = useState<GiftSuggestion[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const lastFetchTime = useRef<number>(0);
  const giftListId = useMemo(() => `gift-list-${recipientId}`, [recipientId]);
  const previousSuggestions = useRef<GiftSuggestion[]>([]);
  const fetchInProgress = useRef<boolean>(false);
  const hasInitialized = useRef<boolean>(false);

  const fetchSuggestions = useCallback(async () => {
    // Skip if a fetch is already in progress
    if (fetchInProgress.current) {
      return;
    }

    // Skip fetch if we're editing
    if (isEditing) {
      if (previousSuggestions.current.length > 0) {
        setSuggestions(previousSuggestions.current);
      }
      return;
    }

    // Rate limiting - except for initial load
    const now = Date.now();
    if (hasInitialized.current && now - lastFetchTime.current < 1000) {
      return;
    }

    fetchInProgress.current = true;
    lastFetchTime.current = now;
    
    try {
      setLoading(true);
      const response = await giftsAPI.getSuggestions(recipientId);
      
      if (response.data.success) {
        const sortedSuggestions = [...(response.data.data || [])].sort((a, b) => {
          const indexA = typeof a.order_index === 'number' ? a.order_index : 0;
          const indexB = typeof b.order_index === 'number' ? b.order_index : 0;
          return indexA - indexB;
        });
        setSuggestions(sortedSuggestions);
        
        // If there are no suggestions and we have a callback, trigger it
        if (sortedSuggestions.length === 0 && onEmptySuggestions) {
          onEmptySuggestions();
        }
        previousSuggestions.current = sortedSuggestions;
      }
    } catch (err) {
      setError('Failed to fetch suggestions');
    } finally {
      setLoading(false);
      fetchInProgress.current = false;
      hasInitialized.current = true;
    }
  }, [recipientId, isEditing, onEmptySuggestions]);

  // Main effect for initialization and event handling
  useEffect(() => {
    const element = document.getElementById(giftListId);
    if (!element) {
      return;
    }

    const handleRefresh = (event: Event) => {
      const customEvent = event as CustomEvent;
      const eventType = customEvent.detail?.type;
      
      if (isEditing) {
        return;
      }

      if (customEvent.detail?.type === 'ai-generate' && customEvent.detail?.suggestions) {
        setSuggestions(customEvent.detail.suggestions);
        previousSuggestions.current = customEvent.detail.suggestions;
        return;
      }

      if (customEvent.detail?.type === 'suggestion-change' && customEvent.detail?.suggestions) {
        setSuggestions(customEvent.detail.suggestions);
        previousSuggestions.current = customEvent.detail.suggestions;
        return;
      }

      fetchSuggestions();
    };

    element.addEventListener('refresh', handleRefresh);

    // Initial setup
    if (!hasInitialized.current && !isEditing) {
      fetchSuggestions();
    } else if (isEditing && previousSuggestions.current.length > 0) {
      setSuggestions(previousSuggestions.current);
    }

    return () => {
      element.removeEventListener('refresh', handleRefresh);
    };
  }, [giftListId, fetchSuggestions, isEditing, recipientId]);

  const debouncedFetchRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFetch = useCallback(() => {
    if (debouncedFetchRef.current) {
      clearTimeout(debouncedFetchRef.current);
    }
    
    debouncedFetchRef.current = setTimeout(() => {
      fetchSuggestions();
      debouncedFetchRef.current = null;
    }, 300);
  }, [fetchSuggestions]);

  // Handle refresh events
  const handleRefresh = useCallback((event: Event) => {
    const customEvent = event as CustomEvent;
    const eventType = customEvent.detail?.type;
    
    if (eventType === 'ai-generate') {
      if (customEvent.detail?.suggestions) {
        setSuggestions(customEvent.detail.suggestions);
      }
      return;
    }

    if (eventType === 'suggestion-change') {
      fetchSuggestions();
      return;
    }

    if (eventType === 'refresh-suggestions' || 
        (eventType === 'ai-generate' && !customEvent.detail?.suggestions)) {
      debouncedFetch();
    }
  }, [debouncedFetch, fetchSuggestions, recipientId, isEditing]);

  // Setup event listener and initial fetch
  useEffect(() => {
    const element = document.getElementById(giftListId);
    
    if (!element) {
      return;
    }

    const handleRefresh = (event: Event) => {
      const customEvent = event as CustomEvent;
      const eventType = customEvent.detail?.type;
      
      if (eventType === 'ai-generate') {
        if (customEvent.detail?.suggestions) {
          setSuggestions(customEvent.detail.suggestions);
        }
        return;
      }

      if (eventType === 'suggestion-change') {
        fetchSuggestions();
        return;
      }

      if (eventType === 'refresh-suggestions' || 
          (eventType === 'ai-generate' && !customEvent.detail?.suggestions)) {
        debouncedFetch();
      }
    };

    element.addEventListener('refresh', handleRefresh);
    
    // Initial fetch if needed and not editing
    if (element.getAttribute('data-fetch-on-mount') === 'true' && !element.getAttribute('data-awaiting-ai') && !element.getAttribute('data-editing')) {
      debouncedFetch();
    }

    return () => {
      element.removeEventListener('refresh', handleRefresh);
      if (debouncedFetchRef.current) {
        clearTimeout(debouncedFetchRef.current);
      }
    };
  }, [recipientId, handleRefresh, debouncedFetch, giftListId]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5, // Reduced from 8 to make it more responsive
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100, // Reduced from 300 to make it more responsive
        tolerance: 5, // Reduced from 8 to make it more responsive
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(async (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!active || !over) {
      return;
    }

    if (active.id === over.id) {
      return;
    }

    try {
      const oldIndex = suggestions.findIndex((item) => item.id.toString() === active.id);
      const newIndex = suggestions.findIndex((item) => item.id.toString() === over.id);
      
      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      // Optimistically update the UI
      const newItems = arrayMove(suggestions, oldIndex, newIndex);
      setSuggestions(newItems);
      
      // Call the API to update the position
      await giftsAPI.reorderSuggestions(
        recipientId,
        Number(active.id),
        newIndex
      );
    } catch (error) {
      setError('Failed to save the new order. Please try again.');
      // Revert the change if the API call fails
      const oldItems = [...suggestions];
      setSuggestions(oldItems);
    }
  }, [recipientId, suggestions]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = {
        ...formData,
        user_input: true,
        ai_created: false
      };

      let response: AxiosResponse<ApiResponse>;
      if (editingSuggestion) {
        response = await giftsAPI.updateSuggestion(recipientId, editingSuggestion.id, data);
      } else {
        response = await giftsAPI.addSuggestion(recipientId, data);
      }

      if (response.data.success) {
        setSuccess(editingSuggestion ? 'Idea updated successfully' : 'Idea added successfully');
        setShowAddModal(false);
        setEditingSuggestion(null);
        setFormData({
          description: '',
          url: '',
        });
        
        // Always fetch the updated list from the server
        await fetchSuggestions();
      } else {
        setError(response.data.message || 'Failed to save idea');
      }
    } catch (error) {
      setError('Error saving idea');
      console.error('Error saving idea:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      setLoading(true);
      const response = await giftsAPI.deleteSuggestion(recipientId, id);
      if (response.data.success) {
        setSuccess('Idea deleted successfully');
        await fetchSuggestions();
      } else {
        setError(response.data.message || 'Failed to delete idea');
      }
    } catch (error) {
      setError('Error deleting idea');
      console.error('Error deleting idea:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (suggestion: GiftSuggestion) => {
    setEditingSuggestion(suggestion);
    setFormData({
      description: suggestion.description,
      url: suggestion.url || '',
    });
    setShowAddModal(true);
  };

  const handleLike = async (id: number) => {
    try {
      const suggestion = suggestions.find(s => s.id === id);
      if (!suggestion) return;

      const newLikes = suggestion.likes > 0 ? 0 : 1;

      const response = await giftsAPI.updateSuggestion(recipientId, id, {
        ...suggestion,
        likes: newLikes
      });

      if (response.data.success) {
        await fetchSuggestions();
      } else {
        setError('Failed to update likes');
      }
    } catch (error) {
      setError('Error updating likes');
      console.error('Error updating likes:', error);
    }
  };

  const handleDislike = async (id: number) => {
    try {
      const response = await giftsAPI.updateSuggestion(recipientId, id, {
        likes: -1
      });
      
      if (response.data.success) {
        const updatedSuggestions = suggestions.map(s => 
          s.id === id ? { ...s, likes: -1 } : s
        );
        setSuggestions(updatedSuggestions);
        
        // After setting dislike, delete the suggestion
        await handleDelete(id);
      }
    } catch (error) {
      console.error('Error updating suggestion:', error);
      setError('Failed to update suggestion');
    }
  };

  const [formData, setFormData] = useState<FormData>({
    description: '',
    url: '',
  });

  const [showAddModal, setShowAddModal] = useState(false);
  const [editingSuggestion, setEditingSuggestion] = useState<GiftSuggestion | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  return (
    <Box 
      id={giftListId}
      data-fetch-on-mount="true"
      sx={{ 
        width: '100%',
        touchAction: 'pan-y',
        userSelect: 'none',
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        gap: 1, 
        alignItems: 'center',
        mb: 2 
      }}>
        <Typography variant="h6" sx={{ flex: 1 }}>
          Gift Suggestions
        </Typography>
        {isEditing && (
          <Button
            startIcon={<AddIcon />}
            onClick={() => setShowAddModal(true)}
            size="small"
          >
            Add Suggestion
          </Button>
        )}
      </Box>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={suggestions.map(s => s.id.toString())}
          strategy={verticalListSortingStrategy}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {suggestions.map((suggestion, index) => (
              <SortableItem
                key={suggestion.id}
                suggestion={suggestion}
                onLike={handleLike}
                onDislike={handleDislike}
                onEdit={handleEdit}
                onDelete={handleDelete}
                index={index}
                total={suggestions.length}
              />
            ))}
          </Box>
        </SortableContext>
      </DndContext>

      <Box sx={{ 
        display: 'flex',
        gap: 1,
        mt: 2
      }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setShowAddModal(true)}
        >
          Add idea
        </Button>
        <Button
          variant="outlined"
          startIcon={<AutoFixHighIcon />}
          onClick={async () => {
            try {
              setLoading(true);
              const response = await giftsAPI.generateSuggestions(recipientId);
              if (response.data.suggestions) {
                setSuccess('Generated new gift suggestions!');
                // Fetch the updated list
                await fetchSuggestions();
              } else {
                setError('Failed to generate suggestions');
              }
            } catch (error) {
              console.error('Error generating suggestions:', error);
              setError('Failed to generate suggestions');
            } finally {
              setLoading(false);
            }
          }}
        >
          AI suggest
        </Button>
      </Box>

      <Dialog
        open={showAddModal}
        onClose={() => {
          setShowAddModal(false);
          setEditingSuggestion(null);
          setFormData({
            description: '',
            url: '',
          });
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{editingSuggestion ? 'Edit idea' : 'Add idea'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.description}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              name="url"
              label="URL (optional)"
              type="url"
              fullWidth
              variant="outlined"
              value={formData.url}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setShowAddModal(false);
              setEditingSuggestion(null);
              setFormData({
                description: '',
                url: '',
              });
            }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={loading}>
              {editingSuggestion ? 'Save' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={() => setSuccess(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success}
        </Alert>
      </Snackbar>
    </Box>
  );
}, (prevProps, nextProps) => {
  // Only re-render if recipientId changes
  return prevProps.recipientId === nextProps.recipientId;
});

export default GiftList;
