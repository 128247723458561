import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  alpha,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyIcon from '@mui/icons-material/Key';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme as useAppTheme } from '../../contexts/ThemeContext';
import KoFiButton from '../common/KoFiButton';
import { googleLogout } from '@react-oauth/google';

const Navigation: React.FC = () => {
  const { logout, user, updateUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isDarkMode, toggleTheme } = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = React.useState<null | HTMLElement>(null);

  const handleLogout = async () => {
    await logout();
    //console.log('Google logout...');
    googleLogout();
    //console.log('Redericting...');
    navigate('/login');
  };

  const handleMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleSettingsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsMenuAnchor(event.currentTarget);
  };

  const handleCloseMobileMenu = () => {
    setMobileMenuAnchor(null);
  };

  const handleCloseSettingsMenu = () => {
    setSettingsMenuAnchor(null);
  };

  const menuItems = [
    { text: 'Gifts', path: '/' },
    { text: 'Contact Us', path: '/contact' },
    { text: 'Feature Request', path: '/feature-request' },
  ];

  return (
    <AppBar 
      position="static" 
      sx={{ 
        backdropFilter: 'blur(10px)',
        backgroundColor: alpha(theme.palette.background.default, 0.8),
        borderBottom: 1, 
        borderColor: 'divider'
      }}
      elevation={0}
    >
      <Container maxWidth={false} sx={{ px: { xs: 2, sm: 3, md: 2, lg: 2, xl: 2 } }}>
        <Toolbar disableGutters sx={{ minHeight: 64 }}>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              mr: 2,
              textDecoration: 'none',
              color: isDarkMode ? 'inherit' : theme.palette.text.primary,
              fontWeight: 700,
              letterSpacing: '.1rem',
              flexGrow: { xs: 1, md: 0 },
              fontSize: { xs: '1.25rem', md: '1.5rem' },
            }}
          >
            Piky.ai
          </Typography>

          {/* Desktop Navigation */}
          {!isMobile && (
            <Box sx={{ flexGrow: 1, display: 'flex', gap: 2, ml: 4 }}>
              {menuItems.map((item) => (
                <Typography
                  key={item.path}
                  component={Link}
                  to={item.path}
                  sx={{
                    color: 'text.primary',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    opacity: 0.9,
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                >
                  {item.text}
                </Typography>
              ))}
            </Box>
          )}

          {/* Mobile & Settings Menu */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1, 
            ml: 'auto',
            flexGrow: 0,
            position: 'relative',
            zIndex: theme.zIndex.appBar + 1,
          }}>
            {isMobile && (
              <IconButton
                size="small"
                aria-label="menu"
                onClick={handleMobileMenu}
                sx={{
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 2,
                  padding: '8px',
                  color: 'text.primary',
                }}
              >
                <MenuIcon sx={{ fontSize: 22 }} />
              </IconButton>
            )}
            {/* Support button - uncomment to show in navbar
            // <KoFiButton />
            */}
            <Tooltip title="Settings">
              <IconButton
                onClick={handleSettingsMenu}
                size="small"
                sx={{ 
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 2,
                  padding: '8px',
                  color: 'text.primary',
                }}
              >
                <SettingsIcon sx={{ fontSize: 22 }} />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Mobile Menu */}
          <Menu
            anchorEl={mobileMenuAnchor}
            open={Boolean(mobileMenuAnchor)}
            onClose={() => setMobileMenuAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            slotProps={{
              paper: {
                sx: {
                  mt: 1.5,
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
                  minWidth: 180,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  }
                }
              }
            }}
            sx={{
              '& .MuiBackdrop-root': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <MenuItem onClick={() => {
              setMobileMenuAnchor(null);
              navigate('/');
            }}>
              <ListItemIcon>
                <CardGiftcardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Gifts</ListItemText>
            </MenuItem>
            
            <MenuItem onClick={() => {
              setMobileMenuAnchor(null);
              navigate('/contact');
            }}>
              <ListItemIcon>
                <ContactSupportIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Contact Us</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => {
              setMobileMenuAnchor(null);
              navigate('/feature-request');
            }}>
              <ListItemIcon>
                <LightbulbIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Feature Request</ListItemText>
            </MenuItem>
          </Menu>

          {/* Settings Menu */}
          <Menu
            anchorEl={settingsMenuAnchor}
            open={Boolean(settingsMenuAnchor)}
            onClose={handleCloseSettingsMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            slotProps={{
              paper: {
                sx: {
                  mt: 1.5,
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
                  minWidth: 180,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  }
                }
              }
            }}
            sx={{
              '& .MuiBackdrop-root': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <Box sx={{ px: 2, py: 1.5 }}>
              <Typography variant="subtitle2" color="text.secondary">
                {user?.email}
              </Typography>
            </Box>
            <Divider />
            <MenuItem 
              component={Link} 
              to="/profile" 
              onClick={async () => {
                handleCloseSettingsMenu();
                try {
                  const response = await fetch('/api/auth/get-user');
                  const data = await response.json();
                  if (data.success && data.user) {
                    updateUser(data.user);
                  }
                } catch (err) {
                  console.error('Error fetching user data:', err);
                }
              }}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            {/* About page - commented out for initial testing
            <MenuItem onClick={() => navigate('/about')} sx={{ py: 1.5 }}>
              <ListItemIcon>
                <InfoIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>About</ListItemText>
            </MenuItem>
            */}
            <MenuItem onClick={() => navigate('/change-password')} sx={{ py: 1.5 }}>
              <ListItemIcon>
                <KeyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Change Password</ListItemText>
            </MenuItem>
            <MenuItem onClick={toggleTheme} sx={{ py: 1.5 }}>
              <ListItemIcon>
                {isDarkMode ? <Brightness7Icon fontSize="small" /> : <Brightness4Icon fontSize="small" />}
              </ListItemIcon>
              <ListItemText>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</ListItemText>
            </MenuItem>
            {/* Hide Support menu item for initial testing
            <MenuItem onClick={() => navigate('/support')} sx={{ py: 1.5 }}>
              <ListItemIcon>
                <LocalCafeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Support Us</ListItemText>
            </MenuItem>
            */}
            <Divider />
            <MenuItem onClick={handleLogout} sx={{ py: 1.5 }}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
