import React from 'react';
import { Container, Typography, Paper, Box, useTheme } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

const SupportPage: React.FC = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
          borderRadius: 4,
          backdropFilter: 'blur(10px)',
          border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography 
            variant="h2" 
            component="h1" 
            sx={{ 
              fontSize: { xs: '2.5rem', sm: '3rem' },
              fontWeight: 700,
              mb: 1,
              background: theme.palette.mode === 'dark' 
                ? 'linear-gradient(45deg, #ff6b6b 30%, #ff8e8e 90%)'
                : 'linear-gradient(45deg, #ff4b4b 30%, #ff6b6b 90%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              display: 'inline-block'
            }}
          >
            ☕️ Support
          </Typography>
          <Typography 
            variant="subtitle1" 
            color="text.secondary"
            sx={{ 
              mb: 3,
              fontSize: '1.1rem',
              fontWeight: 500
            }}
          >
            Help us keep the magic brewing
          </Typography>
          <Box 
            sx={{ 
              width: '100%',
              height: '1px',
              background: theme.palette.mode === 'dark' 
                ? 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent)'
                : 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent)',
              mb: 3
            }} 
          />
          <Typography 
            variant="body1" 
            color="text.secondary" 
            paragraph 
            sx={{ 
              fontSize: '1rem',
              lineHeight: 1.6,
              mb: 2
            }}
          >
            Piky.ai is a labor of love, created to make decision-making easier and more enjoyable for everyone.
            While we're committed to keeping it free, servers and development do come with costs.
          </Typography>
          <Typography 
            variant="body1" 
            color="text.secondary" 
            paragraph 
            sx={{ 
              fontSize: '1rem',
              lineHeight: 1.6,
              mb: 4
            }}
          >
            If you enjoy using Piky.ai and want to support its development, consider buying us a coffee! Your support helps us maintain and improve the app.
          </Typography>
        </Box>
        
        <Box 
          sx={{ 
            width: '100%',
            borderRadius: 3,
            overflow: 'hidden',
            bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : '#f9f9f9',
            border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
          }}
        >
          <iframe 
            id='kofiframe' 
            src='https://ko-fi.com/pikyai/?hidefeed=true&widget=true&embed=true&preview=true' 
            style={{
              border: 'none',
              width: '100%',
              padding: '4px',
              background: 'transparent',
            }}
            height='712' 
            title='Support Piky.ai'
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default SupportPage;
