import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { contactAPI } from '../services/api';  
import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CreateIcon from '@mui/icons-material/Create';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

// Sophisticated color configuration
const colorConfig = {
  light: {
    header: {
      background: 'rgba(230, 230, 250, 0.5)',  // Soft violet
      hover: 'rgba(230, 230, 250, 0.7)'
    },
    button: {
      primary: '#5C6BC0',    // Indigo
      success: '#2E7D32',    // Dark Green
    }
  },
  dark: {
    header: {
      background: 'rgba(50, 40, 80, 0.3)',     // Deep violet
      hover: 'rgba(50, 40, 80, 0.4)'
    },
    button: {
      primary: '#7986CB',    // Lighter Indigo
      success: '#81C784',    // Lighter Green
    }
  }
};

const Contact: React.FC = () => {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [messageSent, setMessageSent] = useState(false);
  const theme = useTheme();
  const colors = colorConfig[theme.palette.mode as 'light' | 'dark'];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim()) {
      setError('Please enter a message');
      return;
    }

    try {
      setLoading(true);
      const response = await contactAPI.submitMessage(message.trim());

      if (response.data.success) {
        setSuccess('Thank you for your message! We will respond to your email within 24-48 hours.');
        setMessageSent(true);
      } else {
        setError(response.data.message || 'Failed to send message');
      }
    } catch (error) {
      setError('Error sending message. Please try again later.');
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={1} sx={{ overflow: 'hidden' }}>
        <Box sx={{ 
          p: 2, 
          bgcolor: colors.header.background,
          transition: 'background-color 0.2s ease',
          borderBottom: 1,
          borderColor: theme.palette.mode === 'light' 
            ? 'rgba(0, 0, 0, 0.05)' 
            : 'rgba(255, 255, 255, 0.05)',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <ContactSupportIcon sx={{ color: colors.button.primary }} />
          <Typography variant="h5" component="h2">
            Contact Us
          </Typography>
        </Box>
        <Box sx={{ p: 3 }}>
          <Typography paragraph>
            Have questions, suggestions, or need assistance? We're here to help!
          </Typography>
          <Typography paragraph color="textSecondary">
            Your message will be sent from your registered email: {user?.email}
          </Typography>
          <Typography paragraph color="textSecondary" variant="body2">
            We aim to respond to all inquiries within 24-48 hours during business days.
          </Typography>
          <form onSubmit={handleSubmit}>
            {!messageSent ? (
              <>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  disabled={loading}
                  required
                  sx={{ mb: 2 }}
                />
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={loading}
                  sx={{
                    minWidth: 120,
                    color: colors.button.primary,
                    borderColor: colors.button.primary,
                    '&:hover': {
                      borderColor: colors.button.primary,
                      backgroundColor: `${colors.button.primary}10`
                    }
                  }}
                  startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                >
                  Send Message
                </Button>
              </>
            ) : (
              <Box sx={{ 
                textAlign: 'center', 
                py: 3,
                px: 2,
                bgcolor: theme.palette.mode === 'light' 
                  ? 'rgba(46, 125, 50, 0.1)'  // Light green background
                  : 'rgba(129, 199, 132, 0.1)', // Dark green background
                borderRadius: 1
              }}>
                <CheckCircleOutlineIcon 
                  sx={{ 
                    fontSize: 48, 
                    color: colors.button.success,
                    mb: 2
                  }} 
                />
                <Typography variant="h6" sx={{ color: colors.button.success }} gutterBottom>
                  Message Sent Successfully!
                </Typography>
                <Typography color="textSecondary" paragraph>
                  We'll get back to you at {user?.email} within 24-48 hours.
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setMessageSent(false);
                    setMessage('');
                  }}
                  sx={{
                    mt: 2,
                    color: colors.button.success,
                    borderColor: colors.button.success,
                    '&:hover': {
                      borderColor: colors.button.success,
                      backgroundColor: `${colors.button.success}10`
                    }
                  }}
                  startIcon={<CreateIcon />}
                >
                  Send Another Message
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Paper>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={() => setSuccess(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Contact;
