import React, { useEffect } from 'react';
import { Box, Container, useTheme } from '@mui/material';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

const Footer: React.FC = () => {
  const theme = useTheme();

  useEffect(() => {
    try {
      // Push the ad after component mounts
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log('AdSense error:', err);
    }
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        mt: 'auto',
        backgroundColor: theme.palette.mode === 'light' 
          ? 'rgba(0, 0, 0, 0.02)' 
          : 'rgba(255, 255, 255, 0.02)',
        borderTop: 1,
        borderColor: theme.palette.mode === 'light'
          ? 'rgba(0, 0, 0, 0.05)'
          : 'rgba(255, 255, 255, 0.05)',
      }}
    >
      <Container maxWidth={false} sx={{ px: { xs: 2, sm: 3, md: 2, lg: 2, xl: 2 } }}>
         {/* Other footer content */}
         <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1433667428903161" crossOrigin="anonymous"></script>
          {/* FooterAd */}
          <ins className="adsbygoogle"
                 style={{ display: 'block' }}
                 data-ad-client="ca-pub-1433667428903161"
                 data-ad-slot="7842563128"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>   
      </Container>
    </Box>
  );
};

export default Footer;