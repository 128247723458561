export const genderOptions = ['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other'];

export const occasionOptions = ['Christmas', 'Birthday', 'Anniversary', 'Wedding', 'Graduation', 'Baby Shower', 'House Warming', 'Thank You', 'Other'];

export const relationshipOptions = ['Friend', 'Best Friend', 'Partner', 'Spouse', 'Parent', 'Sibling', 'Child', 'Grandparent', 'Aunt/Uncle', 'Cousin', 'Colleague', 'Boss', 'Mentor', 'Other'];

// These are initial suggestions for interests/hobbies that will appear as chips
export const interestSuggestions = [
    // Activities & Hobbies
    'Reading', 'Gaming', 'Cooking', 'Baking', 'Gardening', 'Photography', 'Travel', 'Hiking', 
    'Camping', 'Fishing', 'Painting', 'Drawing', 'Music', 'Dancing', 'Yoga', 'Meditation',
    // Sports
    'Football', 'Basketball', 'Soccer', 'Tennis', 'Golf', 'Swimming', 'Running', 'Cycling',
    // Entertainment
    'Movies', 'TV Shows', 'Anime', 'Theater', 'Concerts', 'Board Games', 'Video Games',
    // Collections
    'Books', 'Comics', 'Vinyl Records', 'Art', 'Antiques', 'Stamps', 'Coins',
    // Tech & Gadgets
    'Technology', 'Computers', 'Smartphones', 'Photography', 'Drones', 'Smart Home',
    // Food & Drink
    'Wine', 'Beer', 'Coffee', 'Tea', 'Cooking', 'Baking', 'Food Tasting',
    // Fashion & Beauty
    'Fashion', 'Makeup', 'Skincare', 'Jewelry', 'Watches', 'Shoes',
    // Home & Living
    'Interior Design', 'DIY', 'Plants', 'Home Improvement', 'Organization',
    // Wellness
    'Fitness', 'Health', 'Nutrition', 'Mindfulness', 'Self-care',
    // Creative
    'Writing', 'Crafting', 'Knitting', 'Sewing', 'Woodworking', 'Pottery',
    // Learning
    'Languages', 'History', 'Science', 'Philosophy', 'Psychology', 'Art History'
];
