import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { authAPI } from '../services/api';
import { User } from '../types/user';

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (email: string, password: string) => Promise<{ success: boolean; error?: string }>;
  loginGoogle: (credential: string) => Promise<{ success: boolean; error?: string }>;
  logout: () => Promise<void>;
  updateUser: (userData: User) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = async () => {
    try {
      console.log('Checking authentication status...');
      const response = await authAPI.getUser();
      console.log('Auth check response:', response);
      
      if (response.data.success && response.data.user) {
        setUser(response.data.user);
        setIsAuthenticated(true);
        console.log('User authenticated:', response.data.user);
      } else {
        console.log('User not authenticated');
        setUser(null);
        setIsAuthenticated(false);
      }
    } catch (error: any) {
      console.error('Error checking auth:', error);
      // Log additional details for debugging
      if (error.response) {
        console.log('Error response:', {
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data
        });
      }
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []); // Empty deps array = only run on mount

  const login = async (email: string, password: string) => {
    try {
      console.log('Attempting login...');
      const response = await authAPI.login(email, password);
      console.log('Login response:', response);
      
      if (response.success && response.token) {
        // Store token in localStorage
        localStorage.setItem('authToken', response.token);
        
        // After successful login, immediately fetch user data
        await checkAuth();
        return { success: true };
      }
      return { success: false, error: response.error || 'Login failed' };
    } catch (error: any) {
      console.error('Login error:', error);
      if (error.response) {
        console.log('Login error response:', {
          status: error.response.status,
          data: error.response.data
        });
      }
      return { 
        success: false, 
        error: error.response?.data?.message || 'Login failed' 
      };
    }
  };


  const loginGoogle = async (credential: string) => {
    try {
      console.log('Attempting login...');
      const response = await authAPI.loginGoogle(credential);
      console.log('Login response:', response);
      
      if (response.success && response.token) {
        // Store token in localStorage
        localStorage.setItem('authToken', response.token);
        
        // After successful login, immediately fetch user data
        await checkAuth();
        return { success: true };
      }
      return { success: false, error: response.error || 'Login failed' };
    } catch (error: any) {
      console.error('Login error:', error);
      if (error.response) {
        console.log('Login error response:', {
          status: error.response.status,
          data: error.response.data
        });
      }
      return { 
        success: false, 
        error: error.response?.data?.message || 'Login failed' 
      };
    }
  };

  const logout = async () => {
    try {
      console.log('Logging out...');
      await authAPI.logout();
      console.log('Logout successful');
      
      // Remove token from localStorage
      localStorage.removeItem('authToken');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setUser(null);
      setIsAuthenticated(false);
    }
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      user,
      login,
      loginGoogle,
      logout,
      updateUser: (userData: User) => setUser(userData),
    }),
    [isAuthenticated, user]
  );

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
